<template>
  <div>
    <vx-card>
      <div class="vx-row flex mb-5" >
        <div class="vx-col lg:w-1/2" align="left">
          <h2 class="mb-5">Support Form Enquiry #{{supportEnquiryData.enquiryId}}</h2>
        </div>
        <div class="vx-col lg:w-1/2" align="right">
          <vs-button
            v-if="supportEnquiryData.status == 'pending'"
            align="right"
            class="mr-3"
            @click="resolveHandler"
          >Resolve
          </vs-button>
          <vs-button
            v-if="supportEnquiryData.status == 'Resolved'"
            align="right"
            class="mr-3 vs-button-success"
            @click="resolveHandler"
            color="#28C76F"
          >Resolved
          </vs-button>
        </div>
      </div>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="support-detail-wrap">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full p-5">
            <label class="font-semibold">Date</label>
            <p>{{ moment(supportEnquiryData.Date).format('DD/MM/YYYY') }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full p-5">
            <label class="font-semibold">Name</label>
            <p>{{ supportEnquiryData.fullName | capitalize }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full p-5">
            <label class="font-semibold">Phone Number</label>
            <p>{{ supportEnquiryData.phoneNumber }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full p-5">
            <label class="font-semibold">Email</label>
            <p>{{ supportEnquiryData.email }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full p-5">
            <label class="font-semibold">Enquiry Type</label>
            <p>{{ supportEnquiryData.enquiryType }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full p-5">
            <label class="font-semibold">Message</label>
            <p>{{ supportEnquiryData.message }}</p>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {
        supportEnquiryData: "",
        enquiryId: ""
      }
    },
    methods: {
      ...mapActions("supportEnquiry", [
        "getSupportEnquiryDetails",
        "resolveSupportEnquiryDetails"
      ]),
      moment(date) {
        return moment(date);
      },
      getEnquiryDetails(id) {
        this.getSupportEnquiryDetails(id).then(res => {
          this.supportEnquiryData = res.data.data
        });
      },
      resolveHandler() {
        this.resolveSupportEnquiryDetails(this.enquiryId).then((res) => {
          if(res.data.data.status === 'Resolved') {
            this.$vs.notify({
              title: 'Success',
              text: "Support enquiry resolved successfully.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
          }
          if(res.data.data.status === 'pending') {
            this.$vs.notify({
              title: 'Pending',
              text: "Support enquiry is still pending.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          }
          this.$router.push({name: 'support-list'});
        })
          .catch(err => {
            this.$vs.notify({
              title: 'Failure',
              text: err.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          })
      }
    },
    created() {
      this.enquiryId = this.$route.params.id;
      this.getEnquiryDetails(this.enquiryId);
    }
  }
</script>
<style scoped lang="scss">
  .support-detail-wrap {
    border: 1px solid #e7e7e7;
    .w-full {
      label {
        position: relative;
        margin-right: 20px;
        &:after {
          content: ':';
          font-weight: 700;
          position: absolute;
          top: -1px;
          right: 0;
        }
      }
      @media (min-width: 768px){
        display: flex;
        label {
          width: 250px;
        }
      }
    }
  }
</style>
